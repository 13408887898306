/** @ignore */
export const DOWN_ARROW = 40;

/** @ignore */
export const LEFT_ARROW = 37;

/** @ignore */
export const RIGHT_ARROW = 39;

/** @ignore */
export const UP_ARROW = 38;
